// COLORS
$color-white: #fff;
$color-black: rgb(25, 25, 24);
$color-light-black: rgb(48, 47, 47);
$color-dark-blue: rgb(0, 40, 120);
$color-purple: rgb(108, 47, 136);
$color-yellow: rgb(237, 201, 62);
$color-red: rgb(172, 26, 47);
$color-grey:rgb(142, 144, 143);
$color-light-grey:rgb(221, 221, 221);

// FONT VARIABLES
$font-headers: "poppins",sans-serif;
$font-primary: 'poppins', sans-serif;
$default-font-size: 1.5rem;

// RESPONSIVE BREAKPOINTS
$screen-largest: 120rem;
$screen-larger: 110rem;
$screen-large: 90rem;
$screen-medium: 80rem;
$screen-small: 60rem;