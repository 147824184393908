// small screen size (sm)
@media all and (min-width: 600px) {}

// medium screen size (lg)
@media only screen and (min-width: 800px) {
    /*=============================================
    =            Navigation Styles            =
    =============================================*/

    #mainNav {
        top: 0;
        width: 45%;
        padding-top: 85px;

        .main-nav {
            .nav-item {
                .dropdown-arrow {
                    flex: 0 1 auto;
                    cursor: pointer;
                    color: $color-yellow;
                    height: 45px;
                    width: 70px;
                    outline: none;
                    border: none;
                    background: none;
                }

                a:link,
                a:visited {
                    padding: 12px;
                }
            }
        }
    }

    .mobile-button {
        display: block;

        &.spin>span:nth-of-type(1) {
            opacity: 0;
        }

        &.spin>span:nth-of-type(2) {
            opacity: 1;
            background: $color-white;
        }

        &.spin>span:nth-of-type(3) {
            opacity: 0;
        }
    }

    /*=====  End of Navigation Styles  ======*/

    /*=============================================
               Main Content Styles            
    =============================================*/
    .content-wrapper {

        .main-section {
            flex: 0 1 95%;
            margin-right: 0;
        }

        .main-aside {
            flex: 0 1 95%;

            .ideas-on-map {
                p {
                    max-width: 50%;
                }
            }
        }
    }

    /*=========  End of Content Styles  =========*/

    /*=============================================
               Home Styles            
    =============================================*/
    #home {

        .content-wrapper {

            .main-section {
                flex: 0 1 95%;
                margin-right: 0;
                display: flex;
                justify-content: space-around;

                .project-news {
                    flex: 0 1 43%;
                }

                .upcoming-events {
                    flex: 0 1 43%;
                }
            }

            .main-aside {
                flex: 0 1 95%;
                display: block;

                .project-partners {
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    align-items: center;

                    li {
                        flex: 0 1 25%;
                        min-width: 25%;
                    }

                    li:last-child {
                        flex: 0 1 35%;
                    }
                }
            }
        }
    }

    /*=========  End of Homr Styles  =========*/

    /*=============================================
               Footer Styles            
    =============================================*/
    #footer {
        flex-wrap: nowrap;
        justify-content: center;
        padding: 20px 20px 100px 20px;


        .footer-nav {
            text-align: right;
            flex: 0 1 65%;
            max-width: 600px;

            ul {
                display: flex;
                justify-content: space-around;
            }
        }

        .footer-copyright {
            text-align: left;
            flex: 0 1 25%;
            max-width: 600px;

            img {
                max-width: 12.5rem;
            }

        }

    }

    /*=========  End of Footer Styles  =========*/

}

@media only screen and (min-width: 1024px) {

    /*=============================================
               Header Styles            
    =============================================*/
    #header {

        .header-wrapper {
            flex: 0 1 95%;
            max-width: 95%;

            .mobile-button {
                display: none;
            }

            .logo {
                flex: 0 1 50%;
                max-width: 650px;
                padding: 0;
            }

            .secondary-nav {
                display: block;
                flex: 0 1 50%;
                max-width: 650px;
                font-size: 1.6rem;
                text-align: right;
                order: 1;
                height: 60px;
                padding: 30px 0 0 0;

                >ul {
                    display: flex;
                    justify-content: flex-end;
                    text-align: center;

                    li {
                        flex: 0 1 27%;
                        padding: 0 15px;
                        list-style-type: none;

                        a {
                            display: block;
                            color: $color-light-black;
                            font-weight: 500;
                        }
                    }

                    li:not(:last-child) {
                        border-right: 1px solid $color-grey;
                    }
                }
            }

            .hero-text {
                flex: 0 1 50%;
                max-width: 650px;
                padding: 0;
            }

            #mainNav {
                flex: 0 1 50%;
                position: relative;
                top: 0px;
                right: 0;
                margin: 40px 0 0 0;
                max-width: 650px;
                display: block;
                order: 3;
                background: transparent;
                padding-top: 0px;

                .main-nav {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-around;

                    .nav-item {
                        padding: 0;
                        border: none;
                        position: relative;
                        display: block;

                        .dropdown-arrow {
                            display: none;
                        }

                        >a:link {
                            font-size: 1.6rem;
                            font-weight: 600;
                            color: $color-white;
                            display: block;
                            padding: 12px 0;
                        }

                        >a:hover {
                            font-weight: 600;
                            color: $color-yellow;
                        }

                        >a:after {
                            content: "";
                            position: absolute;
                            bottom: 0.8rem;
                            left: 0;
                            width: 0%;
                            height: 1px;
                            background: $color-yellow;
                            transform: translateX(0%);
                            transition: width .25s ease;
                        }

                        >a:hover:after {
                            content: "";
                            position: absolute;
                            bottom: 0.8rem;
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background: $color-yellow;
                            transform: translateX(0%);
                        }

                        .dropdown-menu {
                            position: absolute;
                            top: 100%;
                            min-width: 200px;
                            background-color: transparent;
                            list-style-type: none;
                            margin: 0;


                            &[aria-hidden=true] {
                                display: none;
                            }

                            &[aria-hidden=false] {
                                display: block;
                            }

                            a:link,
                            a:visited,
                            a:hover {
                                padding: 6px 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .social-nav {
                flex: 1 0 100%;
                order: 4;
                align-self: flex-end;
                display: flex;
                list-style-type: none;
                padding: 0;

                li {
                    padding-right: 20px;
                }

                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    /*=========  End of Header Styles  =========*/

    /*=============================================
               Main Content Styles            
    =============================================*/
    .content-wrapper {
        flex-wrap: nowrap;
        width: 95%;
        max-width: 1300px;
        margin: 0 auto;

        .main-section {
            flex: 0 1 700px;
            max-width: 700px;
            margin-right: 70px;
        }

        .main-aside {
            flex: 0 1 530px;
            display: flex;
            flex-wrap: wrap;
            max-width: 530px;

            .ideas-on-map {
                width: 468px;
                height: 475px;
                padding: 115px 105px;
                background: url(/images/add_comment_circle.svg) no-repeat center center, url(/images/add_comment_map.png) no-repeat top left;

                p {
                    max-width: 100%;
                }
            }

            .ideas-on-map:focus,
            .ideas-on-map:hover {
                background: url(/images/add_comment_circle_hover.svg) no-repeat center center, url(/images/add_comment_map.png) no-repeat top left;
            }
        }
    }

    /*=========  End of Content Styles  =========*/

    /*=============================================
               Home Styles            
    =============================================*/
    #home {
        .slideshow-background {
            top: 80px;
        }

        #header {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 85px, rgba(0, 26, 89, 1) 85px, rgba(0, 40, 120, 0.4) 95px, rgba(0, 40, 120, 0.2) 130px, rgba(0, 40, 120, 0) 150px), url(/images/home_header_background.png) no-repeat bottom center;
            .header-wrapper {
                .hero-text-title {
                    font-size: 5.5rem;
                    line-height: 5.5rem;
                }
            }
        }

        .content-wrapper {

            .main-section {
                flex: 0 1 530px;
                max-width: 530px;
                margin-right: 70px;
                display: block;

                .upcoming-events {
                    .upcoming-events-item {
                        .event-date {
                            flex: 0 1 20%;
                        }

                        .event-description {
                            flex: 0 1 80%;
                        }
                    }
                }

            }

            .main-aside {
                flex: 0 1 700px;
                max-width: 700px;
                .study-area {
                .study-area-bubble {
                    background: url(/images/shape_speech_bubble.svg) no-repeat top left;
                }
            }
            }
        }
    }

    /*=========  End of Home Styles  =========*/

    /*=============================================
               Footer Styles            
    =============================================*/
    #footer {

        .footer-nav {
            flex: 0 1 45%;
        }

        .footer-copyright {
            flex: 0 1 45%;
        }

    }

    /*=========  End of Footer Styles  =========*/

}

@media only screen and (min-width: 1350px) {

    /*=============================================
               Header Styles            
    =============================================*/

    #header {
        .header-wrapper {
            max-width: 1300px;

            .secondary-nav {

                >ul {

                    li {
                        flex: 0 1 20%;
                        padding: 0;
                    }
                }
            }

            .hero-text-title {
                font-size: 6.2rem;
                line-height: 5.5rem;
            }

            #mainNav {
                .main-nav {
                    .nav-item {

                        >a:link,
                        a:visited {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }


    /*=========  End of Header Styles  =========*/
    /*=============================================
               Home Styles            
    =============================================*/
    #home {
        .slideshow-background {
            top: 75px;
        }

        #header {
            .header-wrapper {

                .hero-text-title {
                    font-size: 6.2rem;
                    line-height: 5.5rem;
                }
            }
        }
    }

    /*=========  End of Home Styles  =========*/

}